export type ExternalTransmissionRow = {
  siteNames: string[] //対象サービスサイト
  category: string[] //分類
  providerName: string[] //事業者名
  serviceName: string[] //サービス名
  privacyPolicy: string //プライバシーポリシー
  purposeOfUse: string[] //利用目的
  example: string[] //送信情報例
}

type Option = {
  company: string
  siteNames: string[]
}

/**
 * 外部送信ポリシーのプルダウンに使用するサイト名一覧
 */
const SITE_NAMES = {
  cr: 'オフィスワーク',
  crmy: 'オフィスワーク（登録者専用サイト）',
  highCareer: 'ハイキャリアエージェントサービス',
  jobsmy: 'スポットワーク',
  jobspark: 'レギュラーワーク',
  ocieteinter: 'OCiETe通訳',
  ocietetrans: 'OCiETe翻訳',
  ocietecolumn: 'OCiETeコラム',
  ocietetodo: 'OCiETeToDO',
  sekai: 'セカイノビジネス',
  myforcom: 'マイページ(企業向け）',
  myforworker: 'マイページ(通訳・翻訳者向け）',
} as const

/**
 * 外部送信ポリシーのプルダウン
 */
export const Options: Option[] = [
  {
    company: '株式会社ミライル',
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.jobsmy,
      SITE_NAMES.jobspark,
      SITE_NAMES.highCareer,
    ],
  },
  {
    company: '株式会社オシエテ',
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
  },
]

/**
 * 外部送信ポリシーの表データ
 */
export const TableList: ExternalTransmissionRow[] = [
  {
    siteNames: [SITE_NAMES.cr, SITE_NAMES.highCareer],
    category: ['アクセス解析'],
    providerName: ['株式会社イルグルム'],
    serviceName: ['AD EBIS'],
    privacyPolicy: 'https://www.yrglm.co.jp/policy/',
    purposeOfUse: ['計測、分析、効果測定、統計データ作成のため'],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'ウェブサイトの閲覧履歴や行動履歴',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.highCareer,
      SITE_NAMES.jobsmy,
      SITE_NAMES.jobspark,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['アクセス解析'],
    providerName: ['Google LLC'],
    serviceName: ['Google アナリティクス'],
    privacyPolicy: 'https://policies.google.com/privacy',
    purposeOfUse: ['計測、分析、効果測定、統計データ作成のため'],
    example: ['クッキーなどデバイスに記録されている識別子'],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.highCareer,
      SITE_NAMES.jobsmy,
      SITE_NAMES.jobspark,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['機能補助'],
    providerName: ['Google LLC'],
    serviceName: ['Google', 'タグマネージャー'],
    privacyPolicy: 'https://policies.google.com/privacy',
    purposeOfUse: ['ウェブサイトにおける各種タグの管理・運用のため'],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'IPアドレス（位置情報や回線種別等の推定に用いられます。）',
      'デバイスの設定言語情報',
      '閲覧したサイトのURLやアプリの画面名',
      '閲覧したサイトやアプリのタイトル',
      '閲覧日時（滞在時間などの算出に用いられます。）',
      'ウェブブラウザやデバイスの種類、バージョン',
      'クリックやタップ等の操作情報',
      'デバイスの画面サイズ等の閲覧環境の情報',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.highCareer,
      SITE_NAMES.jobsmy,
      SITE_NAMES.jobspark,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['機能補助'],
    providerName: ['Google LLC'],
    serviceName: ['Google', 'Search Console'],
    privacyPolicy: 'https://policies.google.com/privacy?hl=ja',
    purposeOfUse: ['ウェブサイトの閲覧の傾向や履歴の分析、広告の表示のため'],
    example: [
      'クライアントID',
      'ウェブサイトの閲覧・行動履歴',
      'サイト利用者の属性情報',
      'サイト利用者の位置情報',
    ],
  },
  {
    siteNames: [SITE_NAMES.cr, SITE_NAMES.highCareer],
    category: ['ソーシャルプラグイン'],
    providerName: ['Meta Platforms, Inc.'],
    serviceName: ['Meta for Developers'],
    privacyPolicy: 'https://www.facebook.com/privacy/policy',
    purposeOfUse: [
      'ウェブサイトの閲覧傾向や履歴の分析のため',
      'フォームの送信状況の分析のため',
    ],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'IPアドレス（位置情報や回線種別等の推定に用いられます。）',
      '閲覧したサイトのURLやアプリの画面名',
      '当社訪問の直前に閲覧したサイトのURL',
      '閲覧日時（滞在時間などの算出に用いられます。）',
      'ウェブブラウザやデバイスの種類、バージョン',
      'デバイスの画面サイズ等の閲覧環境の情報',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.highCareer,
      SITE_NAMES.jobsmy,
      SITE_NAMES.jobspark,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['Google LLC'],
    serviceName: ['Google広告'],
    privacyPolicy: 'https://policies.google.com/privacy',
    purposeOfUse: ['広告のターゲティングのため'],
    example: ['クッキーなどデバイスに記録されている識別子'],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.crmy,
      SITE_NAMES.highCareer,
      SITE_NAMES.jobspark,
      SITE_NAMES.jobsmy,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['LINEヤフー株式会社'],
    serviceName: ['Yahoo!広告'],
    privacyPolicy: 'https://www.lycorp.co.jp/ja/company/privacypolicy/',
    purposeOfUse: ['広告のターゲティングのため'],
    example: ['クッキーなどデバイスに記録されている識別子'],
  },
  {
    siteNames: [
      SITE_NAMES.cr,
      SITE_NAMES.highCareer,
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['Meta Platforms, Inc.'],
    serviceName: ['Facebook広告'],
    privacyPolicy: 'https://www.facebook.com/privacy/policy',
    purposeOfUse: ['広告のターゲティングのため'],
    example: ['クッキーなどデバイスに記録されている識別子'],
  },
  {
    siteNames: [SITE_NAMES.highCareer, SITE_NAMES.crmy],
    category: ['データ収集'],
    providerName: ['ユーソナー株式会社'],
    serviceName: ['ユーソナー'],
    privacyPolicy: 'https://usonar.co.jp/privacy/statement.html#product',
    purposeOfUse: [
      'ウェブサイトへのアクセス状況を送信先企業の企業データベースと照合するため',
    ],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'IPアドレス（位置情報や回線種別、アクセス元の企業や組織の推定に用いられます。）',
      '閲覧したサイトのURLやアプリの画面名',
      '当社訪問の直前に閲覧したサイトのURL',
      '閲覧日時（滞在時間などの算出に用いられます。）',
      'ウェブブラウザやデバイスの種類、バージョン',
      'デバイスの画面サイズ等の閲覧環境の情報',
    ],
  },
  {
    siteNames: [SITE_NAMES.crmy],
    category: ['広告・', 'マーケティング'],
    providerName: ['ソネット・メディア・', 'ネットワークス', '株式会社'],
    serviceName: ['Logicad'],
    privacyPolicy: 'https://www.so-netmedia.jp/privacypolicy/',
    purposeOfUse: [
      '広告のターゲティングのため',
      '送信先のサービスの提供、サービスの維持、向上、新しいサービスの開発のため',
    ],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'ウェブサイトへのアクセス状況',
    ],
  },
  {
    siteNames: [SITE_NAMES.crmy],
    category: ['広告・', 'マーケティング'],
    providerName: ['X Corp.'],
    serviceName: ['Twitter広告'],
    privacyPolicy:
      'https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japan',
    purposeOfUse: ['広告のターゲティングのため'],
    example: ['クッキーなどデバイスに記録されている識別子'],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['HubSpot', 'Japan株式会社'],
    serviceName: ['HubSpot'],
    privacyPolicy:
      'https://legal.hubspot.com/jp/privacy-policy?__hstc=103293855.fbf358d0e6c76fa57d142d7f77f64248.1694080016053.1694080016053.1694080016053.1&__hssc=103293855.1.1694080016054&__hsfp=366909711',
    purposeOfUse: ['ウェブサイトの閲覧の傾向や履歴の分析のため'],
    example: [
      'ウェブサイトへのアクセス状況',
      '利用デバイスの特徴を示す情報（ブラウザの種類やOS情報など）',
      'IPアドレス（アクセス元のロケーション）',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['ウェブツール'],
    providerName: ['PayPal Pte. Ltd.'],
    serviceName: ['PayPal（使用した場合のみ対象）'],
    privacyPolicy: 'https://www.paypal.com/jp/webapps/mpp/ua/privacy-full#2',
    purposeOfUse: ['料金決済/支払いのため'],
    example: ['請求金額等、支払情報（クレジットカード情報は含みません。）'],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['ウェブツール'],
    providerName: ['株式会社もしも'],
    serviceName: ['もしもアフェリエイト'],
    privacyPolicy: 'https://www.moshimo.co.jp/company/privacy',
    purposeOfUse: ['広告の成果計測や不正防止のため'],
    example: [
      'IPアドレス',
      'デバイス、ブラウザ、OSなど、利用環境に関する情報',
      'クッキーなどデバイスに記録されている識別子',
      '成果結果情報（広告成果毎の識別子）',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['機能補助'],
    providerName: ['LINEヤフー株式会社'],
    serviceName: ['Yahoo!', 'タグマネージャー'],
    privacyPolicy: 'https://www.lycorp.co.jp/ja/company/privacypolicy/',
    purposeOfUse: ['閲覧の傾向や履歴の分析のため', '広告効果の分析のため'],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'IPアドレス（位置情報や回線種別等の推定に用いられます。）',
      'デバイスの設定言語情報',
      '閲覧したサイトのURLやアプリの画面名',
      '閲覧したサイトやアプリのタイトル',
      '閲覧日時（滞在時間などの算出に用いられます。）',
      'ウェブブラウザやデバイスの種類、バージョン',
      'クリックやタップ等の操作情報',
      'デバイスの画面サイズ等の閲覧環境の情報',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['Microsoft Corporation'],
    serviceName: ['Microsoft広告'],
    privacyPolicy:
      'https://privacy.microsoft.com/ja-jp/privacystatement#maincookiessimilartechnologiesmodule',
    purposeOfUse: ['広告のターゲティングのため'],
    example: [
      'クッキーなどデバイスに記録されている識別子',
      'ウェブサイトへのアクセス状況',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['X Corp.'],
    serviceName: ['Twitter'],
    privacyPolicy: 'https://twitter.com/ja/privacy',
    purposeOfUse: [
      'ウェブサイトでのソーシャルメディア機能の提供およびサイト利用者に適した広告の表示のため',
    ],
    example: [
      'クライアントID',
      'ウェブサイトへのアクセス状況',
      '特定のウェブコンテンツやメールコンテンツの利用の有無',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['LINEヤフー株式会社'],
    serviceName: ['LINE'],
    privacyPolicy: 'https://www.lycorp.co.jp/ja/company/privacypolicy/',
    purposeOfUse: ['ウェブサイトの閲覧の傾向や履歴の分析、広告の表示のため'],
    example: [
      'ウェブサイトの閲覧・行動履歴（訪問日時、URL等を含みます。）',
      'IPアドレス',
      '機器情報の一部（OS等）',
      'プラグインからのログイン記録',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['データ収集'],
    providerName: ['freee株式会社'],
    serviceName: ['Freee会計'],
    privacyPolicy: 'https://www.freee.co.jp/privacy_policy/',
    purposeOfUse: ['経理処理のため'],
    example: [
      '支払元名称、支払元住所、支払元連絡先、契約プラン名称、請求金額等の請求情報',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['広告・', 'マーケティング'],
    providerName: ['株式会社PLAN-B'],
    serviceName: ['マーケティング支援'],
    privacyPolicy: 'https://www.plan-b.co.jp/privacy_policy/',
    purposeOfUse: ['広告運用や効果の分析のため'],
    example: [
      'ウェブサイトへのアクセス状況',
      '利用デバイスの特徴を示す情報（ブラウザの種類やOS情報など）',
      'IPアドレス（アクセス元のロケーション）',
    ],
  },
  {
    siteNames: [
      SITE_NAMES.ocieteinter,
      SITE_NAMES.ocietetrans,
      SITE_NAMES.ocietecolumn,
      SITE_NAMES.ocietetodo,
      SITE_NAMES.sekai,
      SITE_NAMES.myforcom,
      SITE_NAMES.myforworker,
    ],
    category: ['データ収集'],
    providerName: ['株式会社', 'IDCフロンティア'],
    serviceName: ['IDCFクラウド'],
    privacyPolicy: 'https://www.idcf.jp/jp/privacy.html',
    purposeOfUse: [
      'ウェブサイトの閲覧の傾向や履歴の分析のため',
      '経理処理のため',
    ],
    example: [
      '住所、氏名、電話番号、支払元名称、支払元住所、支払元連絡先、契約プラン名称、請求金額等の請求情報',
    ],
  },
]
